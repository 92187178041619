<template>
  <div>
    <ejs-dialog
        ref="salesPlanByDateSettingPopup"
        :header="'일 목표 등록'"
        width="1600"
        height="auto"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :animationSettings="{ effect: 'None' }"
        @close="onClickClose"
    >
      <div class="window" >
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">일 목표 등록</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add"><erp-button button-div="SAVE" @click.native="onClickInputUnitPricePopupShow">일괄 적용</erp-button></li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <!-- 필터영역 -->
                    <div class="content-lookup">
                      <div class="lookup-left">
                        <ul class="lookup-condition">
                          <li class="field">
                            <ul class="content">
                              <li class="item date">
                                <input-date
                                    ref = "planYm"
                                    :format="DATE_FORMAT_YYYY_MM"
                                    :notClear="true"
                                    depth="Year"
                                    v-model="searchFilter.planYm"
                                />
                              </li>
                            </ul>
                          </li>
                        </ul>
                        <div class="lookup-lookup">
                          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
                        </div>
                      </div>
                    </div>
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-01">
                        <div class="section-header">
                          <div class="header-left">
                            <div class="header-title">일 목표</div>
                          </div>
                        </div>
                        <div class="section-body">
                          <div class="body-grid">
                            <ejs-grid-wrapper
                                ref="grid"
                                v-bind ="initGrid"
                                :height="500"
                                :columns="gridField.gridColumns"
                                :aggregates="gridField.aggregates"
                                @queryCellInfo="onQueryCellInfo"
                                @headerCellInfo="onHeaderCellInfo"
                                @cellEdit="onCellEdit"
                                @cellSaved="gridCellSaved"
                                @gridCheckboxChanged="gridCheckboxChanged"
                            />
                          </div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
          <div class="windowFooter">
            <ul class="button">
              <li class="delete">
                <erp-button button-div="SAVE" @click.native="onReset">초기화</erp-button>
              </li>
              <li class="create keyColor">
                <erp-button button-div="SAVE" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSubmit">저장</erp-button>
              </li>
              <li class="close">
                <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ejs-dialog>
    <!-- 팝업 -->
    <input-unit-price-popup
        v-if="isShowInputUnitPricePopup"
        ref="inputUnitPricePopup"
        @popupClosed="onInputUnitPricePopupClose"
        @popupConfirm="onInputUnitPricePopupConfirm"
        :dayDatasource="gridField.dataSource"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Aggregate, Edit, ExcelExport, Filter, ForeignKey, Group, Page, Resize} from "@syncfusion/ej2-vue-grids";
import SalesPlan from "@/api/v2/management/SalesPlan";
import moment from "moment/moment";
import {DATE_FORMAT_YYYY, DATE_FORMAT_YYYY_MM} from "@/utils/date";
import InputDate from "@/components/common/datetime/InputDate.vue";
import InputUnitPricePopup from "@/views/management/popup/BatchSettingBySalesPlanPopup.vue";

export default {
  name: 'SalesPlanByDateSettingPopup',
  mixins: [commonMixin, messagePopupDialogMixin],
  components: {
    InputUnitPricePopup,
    InputDate,
    EjsGridWrapper,
    ErpButton,
  },
  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      DATE_FORMAT_YYYY_MM,
      isShowInputUnitPricePopup: false,

      //조회 필드
      searchFilter: {
        planYm: null,
      },

      //그리드 필드
      gridField: {
        dataSource: [],
        gridColumns: [],
        aggregates: [],
        fieldName: { // 가변 필드명
          team: [],
          per: [],
          sales: [],
        },
      },

      // 영업 계획 기준
      bsnPlanStdList: [],

    };
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  mounted() {
  },
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return (
          this.isShowInputUnitPricePopup
      );
    },
    initGrid() {
      return {
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Aggregate, Edit, Group],
        dataSource: this.gridField.dataSource,
        allowFiltering: false,
        allowExcelExport: true,
        allowSorting: false,
        allowPaging: false,
        isShowProgress: true,
        isNOColumnDisplay: false,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        resizeSettings: {mode:"Normal"},
        validationModification: true,
      };
    },

  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
//region ******************************* api 호출 *******************************
    async findList() {
      if(!this.searchFilter.planYm) { return; }

      await this.$nextTick();

      this.gridField.fieldName = { team: [], per: [], sales: [], };
      this.gridField.dataSource = [];
      this.bsnPlanStdList = [];
      let teamCol = {}, perCol = {}, teamCols = [], perCols = [], salesPriceCols = [], salesCols = [], aggregates = [];
      this.bsnPlanStdList = await SalesPlan.getStandard({planYear: moment(this.searchFilter.planYm).format(DATE_FORMAT_YYYY)});
      // 일간 계획 경우 주중 주말이 인원수를 앞 컬럼에서 계산 할 수 있으므로 1개의 컬럼만 가지면 됩니다.
      let isMultiPerColumns  = (this.bsnPlanStdList.filter(data => data.planStdType.toString().substring(0,3)==='PER')).length > 1;
      let isMultiTeamColumns =false;// (this.bsnPlanStdList.filter(data => data.planStdType === 'TEAM').length > 1);
      console.log(this.gridField.fieldName);
      // 인원수, 팀수, 매출액
      for(const bsnPlanStd of this.bsnPlanStdList) {
        let priceFieldName = bsnPlanStd.planStdType.toLowerCase()+'Price_'+bsnPlanStd.planStdId;
        let fieldName = bsnPlanStd.planStdType.toLowerCase()+'_'+bsnPlanStd.planStdId;
        switch(bsnPlanStd.planStdType) {
          case "TEAM":
            this.gridField.fieldName[bsnPlanStd.planStdType.toLowerCase()]?.push(fieldName);
            teamCol = { field: fieldName, headerText: bsnPlanStd.planStdNm, textAlign: 'right', allowEditing: true, width: 45, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 2 }, };
            if(isMultiTeamColumns) {
              teamCols.push(teamCol);
            }
            break;
          case "PERWEEKEND":
          case "PERWEEKDAY":
            this.gridField.fieldName["per"]?.push(fieldName);
            perCol = { field: fieldName, headerText: bsnPlanStd.planStdNm, textAlign: 'right', allowEditing: true, width: 45, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 2 }, };
            if(isMultiPerColumns) {
              perCols.push(perCol);
            }
            break;
          case "SALES":
            this.gridField.fieldName[bsnPlanStd.planStdType.toLowerCase()]?.push(fieldName);
            salesPriceCols.push({ field: priceFieldName, headerText: bsnPlanStd.planStdNm, textAlign: 'right', allowEditing: true, width: 70, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 3 }, });
            salesCols.push({ field: fieldName, headerText: bsnPlanStd.planStdNm, textAlign: 'right', allowEditing: true, width: 100, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 3 }, });
            break;
        }
        aggregates.push({
          field: fieldName,
          aggregationType: "TotalCaption",
          customAggregate: (args) => {
            return args?.result?.filter(item => item.bsnFlag).map(item => item[fieldName] || 0).reduce((acc, cur) => acc + cur, 0);
          },
        });
      }

      salesCols.push({ field: "total", headerText: "합계", textAlign: 'right', allowEditing: false, width: 125, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 3 }, });

      this.gridField.aggregates = [
        {
          columns: [
            {
              field: "planDate",
              textAlign: "center",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "total",
              aggregationType: "TotalCaption",
              customAggregate: (args) => {
                return args?.result?.filter(item => item.bsnFlag).map(item => item.total || 0).reduce((acc, cur) => acc + cur, 0);
              },
            },
            ...aggregates
          ]
        }
      ];

      const gridColumns = [
        { field: "planDate",     headerText: "영업일자", textAlign: 'center', allowEditing: false, width: 110, visible: true, type: "string", isPrimaryKey: true,
        valueAccessor: (field, data) => { return `${data.calDate}`; },},
        { field: "bsnFlag",      headerText: "영업일", textAlign: 'center', allowEditing: true, width: 50, visible: true, type: "boolean", editType: "booleanedit", isSelectAllColumn: false, displayAsCheckBox: true, minWidth: 16},
        { field: "bsnCode",      headerText: "영업구분", textAlign: 'center', allowEditing: false, width: 60, visible: true, type: "string", isCommonCodeField: true, groupCode: "BSN_CODE", },
        { field: "teamPerValue", headerText: "팀당인원수", textAlign: 'right', allowEditing: true, width: 60, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 2 }, },
        ( isMultiTeamColumns ? { field: 'TEAM', headerText: '팀수', columns: teamCols, } : teamCol ),
        ( isMultiPerColumns ? { field: 'PER', headerText: '인원', columns: perCols, } : perCol ),
        { field: "unitPrice",    headerText: "객단가", columns: salesPriceCols, },
        { field: 'salesAmt',     headerText: '매출액', columns: salesCols, },
      ];
      console.log(isMultiPerColumns,this.gridField.fieldName,perCols);
      this.gridField.gridColumns = gridColumns;

      await SalesPlan.getPlanYmList({planYm: this.searchFilter.planYm.replace("-", "")})
      .then((response) => {
        // 합계 계산
        response.forEach(data => {
          data.total = this.gridField.fieldName.sales.map(filedName => data[filedName] || 0).reduce((acc, cur) => acc + cur, 0);
        });
        this.gridField.dataSource = response;
      })
      .catch((error) => {
        console.log("getPlanYearList.===>", error);
      });
    },



    //endregion
    //region  ******************************* Button Click *******************************
    onClickSubmit() {
      this.$emit('popupConfirm', {
        planYm: this.searchFilter.planYm.replace("-", ""),
        changedRecords: this.$refs.grid.getBatchChanges().changedRecords,
      });
    },
    async onReset() {
      if (!(await this.confirm("초기화 하시겠습니까?"))) {
        return;
      }

      this.$refs.grid.getBatchCurrentViewRecords().forEach(rowData => {
        this.resetRowData(rowData);
      });
      this.computeVisitGridAggregation();
    },
    onClickClose() {
      this.$emit('popupClosed');
    },
    //조회
    onClickSearch() {
      this.findList();
    },
    // 객단가 입력 팝업
    onClickInputUnitPricePopupShow() {
      if(this.gridField.dataSource.length<0){
        this.infoToast(this.$t("main.popupMessage.noData"));
        return;
      }
      this.isShowInputUnitPricePopup = true;
      this.$nextTick(() => {
        this.$refs.inputUnitPricePopup.initShow({
          planYm: this.searchFilter.planYm,
          dayDatasource: this.$refs.grid.getBatchCurrentViewRecords(),
          selectIndex: this.$refs.grid.getSelectedRowIndexes(),
        });
      });
    },
    //endregion
    //region ******************************* 팝업 이벤트 *******************************
    //####################### 부모,자식 화면에서 호출하는 function ###############################
    initShow(bsnPlanYm) {
      this.$refs.salesPlanByDateSettingPopup.show();
      this.searchFilter.planYm = moment(bsnPlanYm).format(DATE_FORMAT_YYYY_MM);
      this.findList();
    },
    async teamPerValueSetting(rowDatas,args) {
      rowDatas.forEach(rowData => {
        if(rowData.bsnFlag) {
          const rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(rowData.planDate);
          this.$refs.grid.updateCell(rowIndex, "teamPerValue", args.teamPerValue);
          this.$refs.grid.updateCell(rowIndex, this.gridField.fieldName.team[0], args.teamValue);
          // 인원수 = 팀당인원수 * 팀
          let perCnt = args.teamPerValue * args.teamValue;


          if(rowData.bsnCode === "WEEKDAY") {
            this.$refs.grid.updateCell(rowIndex, this.gridField.fieldName.per[0], this.floor(perCnt, 3));
            rowData[this.gridField.fieldName.per[0]] = this.floor(perCnt, 3);
          }else {
            this.$refs.grid.updateCell(rowIndex, this.gridField.fieldName.per[1], this.floor(perCnt, 3));
            rowData[this.gridField.fieldName.per[1]] = this.floor(perCnt, 3);
          }
          // 객단가에 맞춰 각 매출액 수정
          this.gridField.fieldName.sales.forEach(fieldName => {
            let bsnPlanStd = this.bsnPlanStdList.filter(data => data.planStdId == fieldName.slice(fieldName.indexOf("_")+1))[0];
            // 매출액 = (주중,주말 비율) * 인원 * 객단가
            let salesAmt = (bsnPlanStd[rowData.bsnCode] || 1) * perCnt * rowData[this.getPriceFieldName(fieldName)];
            this.$refs.grid.updateCell(rowIndex, fieldName, this.floor(salesAmt, 3));
            rowData[fieldName] = this.floor(salesAmt, 3);
          });

          this.recalculationTotal(rowData);
          this.$refs.grid.updateCell(rowIndex, "total", rowData.total);
        }
      });

      this.computeVisitGridAggregation();
    },

    async onInputUnitPricePopupConfirm(args) {
      // this.isShowInputUnitPricePopup = false;
      let rowDatas;

      // 객단가 적용 대상 필터
      if(args.planDay) {
        rowDatas = this.$refs.grid.getBatchCurrentViewRecords().filter((data, index) => index == this.$refs.grid.getRowIndexByPrimaryKey(args.planDate));
      } else if(args.bsnCode) {
        rowDatas = this.$refs.grid.getBatchCurrentViewRecords().filter(data => data.bsnCode == args.bsnCode);
      } else {
        rowDatas = this.$refs.grid.getBatchCurrentViewRecords();
      }

      // 인원 관련 파라미터가 있을때만 실행
      if(args.teamPer) {
        await this.teamPerValueSetting(rowDatas, args.teamPer);
      }

      rowDatas.forEach(rowData => {
        if(rowData.bsnFlag) {
          const rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(rowData.planDate);
          args.changedRecords?.forEach(unitPriceData => {
            let fieldName = unitPriceData.planStdType.toLowerCase()+"_"+unitPriceData.planStdId;
            let priceFieldName = unitPriceData.planStdType.toLowerCase()+"Price_"+unitPriceData.planStdId;
            // 각 객단가 수정
            this.$refs.grid.updateCell(rowIndex, priceFieldName, unitPriceData.unitPrice);

            // 객단가에 맞춰 각 매출액 수정
            let bsnPlanStd = this.bsnPlanStdList.filter(data => data.planStdId == unitPriceData.planStdId)[0];
            // 매출액 = (주중,주말 비율) * 인원 * 객단가
            let val = 0;
            if(rowData.bsnCode === "WEEKDAY") {
              val = (bsnPlanStd[rowData.bsnCode] || 1) * rowData[this.gridField.fieldName.per[0]] * unitPriceData.unitPrice;
              this.$refs.grid.updateCell(rowIndex, fieldName, this.floor(val, 3));
            }else {
              val = (bsnPlanStd[rowData.bsnCode] || 1) * rowData[this.gridField.fieldName.per[1]] * unitPriceData.unitPrice;
              this.$refs.grid.updateCell(rowIndex, fieldName, this.floor(val, 3));
            }
            rowData[unitPriceData.planStdType.toLowerCase()+"_"+unitPriceData.planStdId] = this.floor(val, 3);
          });

          this.recalculationTotal(rowData);
          this.$refs.grid.updateCell(rowIndex, "total", rowData.total);
        }
      });

      this.computeVisitGridAggregation();

    },
    onInputUnitPricePopupClose() {
      this.isShowInputUnitPricePopup = false;
    },
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    // 내장객 그리드 집계 정보 갱신
    computeVisitGridAggregation() {
      /** 그리드 에디터를 통한 수정이 아닐경우 집계 관련 정보가 갱신이 안되는 버그 임시 조치
       *  레코드 추가 > 삭제 > eidtCell을 통한 스크롤 상단 이동을 통해 강제 갱신
       * */
      this.$refs.grid.addRecord({}, 0);
      this.$refs.grid.deleteRecord();
      this.$refs.grid.editCell(0, "teamPerValue");
    },
    floor(data, maxUnderDotLength){
      if(maxUnderDotLength < 1) {
        return data;
      }
      let pow = 10 ** maxUnderDotLength;
      return Math.floor(data * pow) / pow;
    },
    getPriceFieldName(fieldName) {
      return fieldName.slice(0,fieldName.indexOf("_")) + "Price" + fieldName.slice(fieldName.indexOf("_"));
    },
    getFieldName(priceFieldName) {
      return priceFieldName.replace("Price", "");
    },
    // RowData Reset
    resetRowData(rowData) {
      const rowIndex = this.$refs.grid.getRowIndexByPrimaryKey(rowData.planDate);
      this.$refs.grid.updateCell(rowIndex, "bsnFlag", (rowData.bsnCode == 'CLOSE' ? false : true));
      this.$refs.grid.updateCell(rowIndex, "total", 0);
      this.$refs.grid.updateCell(rowIndex, "teamPerValue", 0);
      this.$refs.grid.updateCell(rowIndex, this.gridField.fieldName.team[0], 0);
      this.gridField.fieldName.per.forEach(fieldName => {
      this.$refs.grid.updateCell(rowIndex, fieldName, 0);
      });
      this.gridField.fieldName.sales.forEach(fieldName => {
        this.$refs.grid.updateCell(rowIndex, this.getPriceFieldName(fieldName), 0);
        this.$refs.grid.updateCell(rowIndex, fieldName, 0);
      });
    },
    // 합계 Update
    recalculationTotal(rowData) {
      rowData.total = this.floor(this.gridField.fieldName.sales.map(fieldName => rowData[fieldName] || 0).reduce((acc, cur) => acc + cur, 0), 3);
    },
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if(field === 'teamPerValue') {
        node.querySelector(".e-headercelldiv").style.lineHeight = '#975b8c';
        node.querySelector(".e-headertext").innerHTML = '팀당<br/>인원수';
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (field === 'planYm') {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
      if (field === 'bsnCode') {
        const data = args.data;
        // 영업구분
        if (data.bsnCode === 'WEEKEND') {
          // 빨간색 : 영업구분이 주말
          cell.style.color = '#FF0000';
        }
      }
      if(field.indexOf("per")>-1){
       cell.allowEditing = true;
      }
    },
    onCellEdit(args){
      const { columnName, rowData } = args;
      if(columnName === this.gridField.fieldName.per[1] && rowData.bsnCode =="WEEKDAY") {
         this.$refs.grid.endEdit();
      }
      if( rowData.bsnCode =="WEEKEND" && columnName === this.gridField.fieldName.per[0]){
        this.$refs.grid.endEdit();
      }
    },
    // onGridClick(args){
    //   this.$refs.grid.getColumnObject(this.gridField.fieldName.per[0]).allowEditing = args.rowData.bsnCode == "WEEKDAY";
    //   this.$refs.grid.getColumnObject(this.gridField.fieldName.per[1]).allowEditing = args.rowData.bsnCode == "WEEKEND";
    // },
    //그리드 저장 후
    async gridCellSaved(args){
      const { column:{ field }, rowData } = args;
      if(args.previousValue === args.value || field === "bsnFlag") {
        return;
      }
      rowData[field] = args.value;
      console.log(this.gridField.fieldName.per,this.gridField.fieldName.team);
      // 팀당인원수, 팀수, 인원수 수정 시
      if(field === "teamPerValue"
          || field === this.gridField.fieldName.team[0]
          || field === this.gridField.fieldName.per[0]|| field === this.gridField.fieldName.per[1]) {
        if(field === "teamPerValue" || field === this.gridField.fieldName.per[0]|| field === this.gridField.fieldName.per[1]) {
          // 팀수 = 인원수 / 팀당인원수
          let val = 0;
          if(rowData.bsnCode === "WEEKDAY") {
             val = rowData[this.gridField.fieldName.per[0]] / rowData.teamPerValue;
          }else{
            val = rowData[this.gridField.fieldName.per[1]] / rowData.teamPerValue;
          }
          rowData[this.gridField.fieldName.team[0]] = this.floor(val, 2);
        }

        if(field === this.gridField.fieldName.team[0]) {
          // 인원수 = 팀당인원수 * 팀
          let val = rowData.teamPerValue * rowData[this.gridField.fieldName.team[0]];
          if(rowData.bsnCode === "WEEKDAY") {
            rowData[this.gridField.fieldName.per[0]] = this.floor(val, 2);
          }else{
            rowData[this.gridField.fieldName.per[1]] = this.floor(val, 2);
          }
        }

        // 객단가에 맞춰 각 매출액 수정
        this.gridField.fieldName.sales.forEach(fieldName => {
          let bsnPlanStd = this.bsnPlanStdList.filter(data => data.planStdId == fieldName.slice(fieldName.indexOf("_")+1))[0];
          // 매출액 = (주중,주말 비율) * 인원 * 객단가
          if(rowData.bsnCode === "WEEKDAY") {
            let val = (bsnPlanStd[rowData.bsnCode] || 1) * rowData[this.gridField.fieldName.per[0]] * rowData[this.getPriceFieldName(fieldName)];
            rowData[fieldName] = this.floor(val, 3);
          }else{
            let val = (bsnPlanStd[rowData.bsnCode] || 1) * rowData[this.gridField.fieldName.per[1]] * rowData[this.getPriceFieldName(fieldName)];
            rowData[fieldName] = this.floor(val, 3);
          }
        });
      }

      //객단가 수정 시
      if(this.gridField.fieldName.sales.filter(fieldName => this.getPriceFieldName(fieldName) === field).length > 0) {
        let bsnPlanStd = this.bsnPlanStdList.filter(data => data.planStdId == field.slice(field.indexOf("_")+1))[0];
        // 매출액 = (주중,주말 비율) * 인원 * 객단가
        if(rowData.bsnCode === "WEEKDAY") {
          let val = (bsnPlanStd[rowData.bsnCode] || 1) * rowData[this.gridField.fieldName.per[0]] * rowData[field];
          rowData[this.getFieldName(field)] = this.floor(val, 3);
        }else {
          let val = (bsnPlanStd[rowData.bsnCode] || 1) * rowData[this.gridField.fieldName.per[1]] * rowData[field];
          rowData[this.getFieldName(field)] = this.floor(val, 3);
        }
      }

      // 합계 Update
      if(field === "teamPerValue"
          || field === this.gridField.fieldName.team[0]
          || field.indexOf("per") > -1
          || field.indexOf("sales") > -1) {
        this.recalculationTotal(rowData);
      }

      this.$refs.grid.updateRow(this.$refs.grid.getRowIndexByPrimaryKey(args.rowData.planDate), rowData);
    },
    //그리드 체크박스 수정
    async gridCheckboxChanged(args){
      await this.$nextTick();
      this.$refs.grid.saveCell();
      const { rowIndex, columnName, rowData } = args;

      // 팀수, 인원수가 가변일 경우에는 계산방식 수정
      if(columnName === "bsnFlag") {
        if(!args.value) {
          this.resetRowData(rowData);
          this.$refs.grid.updateCell(rowIndex, columnName, args.value);
        }
      }
    },
    //endregion
  },
};
</script>
<style scoped>
</style>