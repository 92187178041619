<template>
  <div>
    <ejs-dialog
        ref="inputUnitPricePopup"
        :header="'일 목표 객단가'"
        width="900"
        height="auto"
        :allowDragging="true"
        :showCloseIcon="true"
        :isModal="true"
        :animationSettings="{ effect: 'None' }"
        @close="onClickClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <!-- 필터영역 -->
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <ul class="content">
                      <li class="item date">
                        <input-date
                            ref="planYm"
                            :format="DATE_FORMAT_YYYY_MM"
                            :notClear="true"
                            :disabled="true"
                            depth="Year"
                            v-model="searchFilter.planYm"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item date">
                        <ejs-dropdownlist
                            v-model="searchFilter.planDay"
                            :dataSource="searchOptions.days"
                            :allowFiltering="false"
                            :fields="{ text: 'dayName', value: 'dayCode' }"
                            cssClass="lookup-condition-dropdown"
                            @change="onChangePlanDate"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item date">
                        <ejs-dropdownlist
                            v-model="searchFilter.bsnCode"
                            :dataSource="searchOptions.bsnCode"
                            :allowFiltering="false"
                            :fields="{ text: 'comName', value: 'comCode' }"
                            :enabled="isBsnCode"
                            cssClass="lookup-condition-dropdown"
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened"
                              @click.native="onClickSearch">조회
                  </erp-button>
                </div>
              </div>
            </div>
          </div>
          <div class="content-body">
            <!-- 활성 : active -->
            <article class="body-article">
              <!-- 아코디언 : accordion / 닫힘 : close -->
              <section class="article-section section-01">
                <div class="body-data">
                  <div class="section-header">
                    <div class="data-inner">
                      <ul class="data-content">
                        <li class="field">
                          <div class="title">팀장인원수</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group number">
                                <div class="form">
                                  <input-number
                                      ref="teamPerValue"
                                      :min="0"
                                      :allow-dot="true"
                                      v-model="dataField.teamPerValue"
                                  />
                                </div>

                              </li>
                            </ul>
                          </div>
                        </li>
                        <li class="field">
                          <div class="title ">팀수</div>
                          <div class="content">
                            <ul class="row">
                              <li class="item form-group number">
                                <div class="form">
                                  <input-number
                                      ref="teamPerValue"
                                      :min="0"
                                      v-model="dataField.teamValue"
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="section-header">
                  <div class="header-left">
                    <div class="header-caption"></div>
                  </div>
                </div>

                <div class="section-body">
                  <div class="body-grid">
                  <ejs-grid-wrapper
                      ref="grid"
                      v-bind="initGrid"
                      :height="200"
                  />
                  </div>
                </div>
              </section>
            </article>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="create keyColor">
              <erp-button button-div="SAVE" :is-shortcut-button="false" :ignore="isPopupOpened"
                          @click.native="onClickPerSubmit">인원수 적용
              </erp-button>
            </li>
            <li class="create keyColor">
              <erp-button button-div="SAVE" :is-shortcut-button="false" :ignore="isPopupOpened"
                          @click.native="onClickUnitPriceSubmit">객단가 적용
              </erp-button>
            </li>
            <li class="create keyColor">
              <erp-button button-div="SAVE" :is-shortcut-button="true" :ignore="isPopupOpened"
                          @click.native="onClickTotalSubmit">적용
              </erp-button>
            </li>
            <li class="close">
              <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {Edit, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import SalesPlan from "@/api/v2/management/SalesPlan";
import {DATE_FORMAT_YYYY_MM} from "@/utils/date";
import InputDate from "@/components/common/datetime/InputDate.vue";
import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import moment from "moment";
import InputNumber from "@/components/common/InputNumber.vue";

export default {
  name: 'InputUnitPricePopup',
  mixins: [commonMixin, messagePopupDialogMixin],
  components: {
    InputNumber,
    InputDate,
    EjsGridWrapper,
    ErpButton,
  },
  beforeMount() {
    // 검색 조건 설정
    this.searchOptions.bsnCode.unshift({
      comCode: "",
      comName: "전체",
    });
  },
  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      DATE_FORMAT_YYYY_MM,
      isBsnCode: true,
      dataField: {
        selectIndex:0,
        teamPerValue:0,
        teamValue:0,
      },
      dayDatasource: [],

      //드롭다운 코드
      searchOptions: {
        bsnCode: commonCodesGetCommonCode("BSN_CODE", false, false,).filter(data => data.comCode !== 'CLOSE'),
        days: [],
      },

      //조회 필드
      searchFilter: {
        planYm: null,
        planDay: '',
        bsnCode: '',
      },

      //그리드 필드
      gridField: {
        dataSource: [],
      },
    };
  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
  },
  mounted() {
  },
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return false;
    },
    initGrid() {
      const gridColumns = [
        {field: "planStdId",        headerText: "id", visible: false, type: "string", isPrimaryKey: true,},
        {field: "planStdNm",        headerText: "기준명칭",          textAlign: 'center', allowEditing: false, width: 120, visible: true, type: "string",},
        {field: "sortSno",          headerText: "순서",              textAlign: 'center', allowEditing: false, width: 50,  visible: true, type: "string",},
        {field: "className",        headerText: "상품분류",          textAlign: 'left',   allowEditing: false, width: 120, visible: true, type: "string",},
        {field: "beforeUnitPrice",  headerText: "전년실적 객단가",   textAlign: 'right',  allowEditing: false, width: 120, visible: true, type: "number"
          , isNumericType: true, inputNumberProperty: {allowDot: true, maxUnderDotLength: 3}/*, valueAccessor: (field, data) => { return data[field] ? data[field] : '0'; }*/,},
        {field: "unitPrice",        headerText: "객단가",            textAlign: 'right',   allowEditing: true, width: 120, visible: true, type: "number"
          , isNumericType: true, inputNumberProperty: {allowDot: true, maxUnderDotLength: 3},},
      ];

      return {
        provides: [ForeignKey, Resize, Edit],
        dataSource: this.gridField.dataSource,
        columns: gridColumns,
        allowFiltering: false,
        allowExcelExport: true,
        allowSorting: false,
        allowPaging: false,
        isShowProgress: true,
        isNOColumnDisplay: false,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        resizeSettings: {mode: "Normal"},
        validationModification: true,
      };
    },
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
//region ******************************* api 호출 *******************************
    async findList() {
      if (!this.searchFilter.planYm) {
        return;
      }

      await this.$nextTick();

      await SalesPlan.getUnitPriceList({
        planYm: this.searchFilter.planYm.replace("-", ""),
        planDay: this.searchFilter.planDay,
        bsnCode: this.searchFilter.bsnCode,
      })
          .then((response) => {
            // 일 목표 등록 팝업의 현재 객단가 세팅
            // const rowData = this.dayDatasource.filter(data => data.planDate === this.searchFilter.planYm.replace("-", "") + this.searchFilter.planDay);
            const rowData = this.dayDatasource[this.dataField.selectIndex];
            response.forEach(data => {
              let price = 0;
              price = rowData[data.planStdType.toLowerCase() + "Price_" + data.planStdId];
              data.unitPrice = price;
              data.beforeUnitPrice =  data.beforeUnitPrice||"";
            });

            this.gridField.dataSource = response;
          })
          .catch((error) => {
            console.log("getPlanYearList.===>", error);
          });
    },
//endregion
//region ******************************* Button Click *******************************
    onClickPerSubmit() {
      this.$emit('popupConfirm', {
        planDate: this.searchFilter.planYm.replace("-", "") + this.searchFilter.planDay,
        planDay: this.searchFilter.planDay,
        bsnCode: this.searchFilter.bsnCode,
        teamPer: this.dataField,
      });
      this.infoToast(this.$t('main.popupMessage.patched'));
    },
    onClickUnitPriceSubmit() {
      this.$emit('popupConfirm', {
        planDate: this.searchFilter.planYm.replace("-", "") + this.searchFilter.planDay,
        planDay: this.searchFilter.planDay,
        bsnCode: this.searchFilter.bsnCode,
        changedRecords: this.$refs.grid.getBatchChanges().changedRecords,
      });
      this.infoToast(this.$t('main.popupMessage.patched'));
    },
    onClickTotalSubmit() {
      this.$emit('popupConfirm', {
        planDate: this.searchFilter.planYm.replace("-", "") + this.searchFilter.planDay,
        planDay: this.searchFilter.planDay,
        bsnCode: this.searchFilter.bsnCode,
        teamPer: this.dataField,
        changedRecords: this.$refs.grid.getBatchChanges().changedRecords,
      });
      this.infoToast(this.$t('main.popupMessage.patched'));
    },
    onClickClose() {
      this.$emit('popupClosed');
    },
    //조회
    async onClickSearch() {
      await this.findList();
    },

    //endregion
    //region ******************************* 팝업 이벤트 *******************************
    //####################### 부모화면에서 호출하는 function ###############################
    initShow(args) {
      this.searchFilter.planYm = args.planYm;
      this.dayDatasource = args.dayDatasource;
      this.setDayList(args.planYm);

      const teamColNm = Object.keys(args.dayDatasource[args.selectIndex]).filter(key=> key.indexOf("team_")===0 )[0];
      this.dataField.selectIndex = args.selectIndex; //
      this.dataField.teamPerValue = args.dayDatasource[args.selectIndex]["teamPerValue"]; //
      this.dataField.teamValue = args.dayDatasource[args.selectIndex][teamColNm];
      this.findList();
    },
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    setDayList(planYm) {
      this.searchFilter.planDay = '';
      this.searchOptions.days = [{dayCode: '', dayName: '전체'}];
      for (let i = 1; i <= moment(planYm).daysInMonth(); i++) {
        this.searchOptions.days.push({dayCode: `${i < 10 ? '0' + i : i}`, dayName: i + '일'});
      }
    },
    onChangePlanDate(args) {
      if (args.value == '') {
        this.isBsnCode = true;
      } else {
        this.isBsnCode = false;
        this.searchFilter.bsnCode = '';
      }
    }
    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //endregion
  },
};
</script>
<style scoped>
</style>