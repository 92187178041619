<template>
  <div class="content-wrapper">
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">기준년도</div>
            <ul class="content">
              <li class="item date">
                <input-date
                    ref = "planYear"
                    :format="DATE_FORMAT_YYYY"
                    :notClear="true"
                    depth="Decade"
                    v-model="searchFilter.planYear"
                    type="lookup-condition"
                    @change="onYearChange"
                />

              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>

    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article">
        <!-- ######## 목록 리스트 ########-->
        <section class="article-section">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">{{gridField.title}}</div>
              <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="save keyColor"><erp-button button-div="SAVE" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSaveSalesPlan" v-show="salesPlanDiv!=='DAY'">저장</erp-button></li>
                <li class="add"><erp-button button-div="SAVE" :ignore="isPopupOpened" @click.native="onClickAddSalesPlan" v-show="gridField.count===0">영업계획 생성</erp-button></li>
                <li class="delete"><erp-button button-div="DELETE" :ignore="isPopupOpened" @click.native="onClickDeleteSalesPlan" v-show="gridField.count>0">영업계획 삭제</erp-button></li>
                <li class="print"><erp-button button-div="FILE" :ignore="isPopupOpened" @click.native="onClickExcel">Excel</erp-button></li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  v-bind ="initGrid"
                  :columns="gridField.gridColumns"
                  @recordClick="onGridClick"
                  @cellSaved="gridCellSaved"
                  @queryCellInfo="onQueryCellInfo"
                  @headerCellInfo="onHeaderCellInfo"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>

          <div class="section-header prev-year-list">
            <div class="header-left">
              <div class="header-title">{{prevYearGridField.title}}</div>
              <div class="header-caption">[ {{ prevYearGridField.count }} 건 ]</div>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="prevYearGrid"
                  v-bind ="initPrevYearGrid"
                  :columns="prevYearGridField.gridColumns"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <!-- 팝업 -->
    <sales-plan-by-date-setting-popup
        v-if="isShowSalesPlanByDateSettingPopup"
        ref="createByDatePopup"
        @popupClosed="onSalesPlanByDateSettingPopupClose"
        @popupConfirm="onSalesPlanByDateSettingPopupConfirm"
    />
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import InputDate from "@/components/common/datetime/InputDate.vue";
import {DATE_FORMAT_YYYY, DATE_FORMAT_YYYY_MM} from "@/utils/date";
import moment from "moment";
import SalesPlan from "@/api/v2/management/SalesPlan";
import SalesPlanByDateSettingPopup from "@/views/management/popup/SalesPlanByDateSettingPopup.vue";
import {commonCodesGetStandardInfo} from "@/utils/commonCodes";


export default {
  name: "SalesPlanStd",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {SalesPlanByDateSettingPopup, ErpButton, EjsGridWrapper, InputDate,},
  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      DATE_FORMAT_YYYY,
      DATE_FORMAT_YYYY_MM,
      isShowSalesPlanByDateSettingPopup:false,
      salesPlanDiv :  commonCodesGetStandardInfo("salesPlanDiv"),
      //조회 필드
      searchFilter: {
        planYear: null,
      },

      //그리드 필드
      gridField: {
        title: "영업계획 등록",
        count : 0,
        dataSource: [],
        gridColumns: [],
        fieldName: { // 가변 필드명
          team: [],
          per: [],
          sales: [],
        },
      },

      prevYearGridField: {
        title: "전년도 계획",
        count : 0,
        dataSource: [],
        gridColumns: [],
        fieldName: {
          team: [],
          per: [],
          sales: [],
        },
      },
    };
  },
  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return (
          this.isShowSalesPlanByDateSettingPopup
      );
    },

    initGrid() {
      return {
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,
        allowFiltering: false,
        allowExcelExport: true,
        allowSorting: false,
        allowPaging: false,
        isShowProgress: true,
        isNOColumnDisplay: false,
        resizeSettings: {mode:"Normal"},
        validationModification: true,
      };
    },
    initPrevYearGrid() {
      return {
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.prevYearGridField.dataSource,
        allowFiltering: false,
        allowExcelExport: true,
        allowSorting: false,
        allowPaging: false,
        isShowProgress: true,
        isNOColumnDisplay: false,
        resizeSettings: {mode:"Normal"},
        validationModification: true,
      };
    },

  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  async created() {
    this.searchFilter.planYear = moment().format(DATE_FORMAT_YYYY);  //마감년 기본 값
  },
  mounted() {
    this.onClickSearch();
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //region ******************************* api 호출 *******************************
    async findList() {
      await this.getList(this.gridField, this.searchFilter ,this.salesPlanDiv === 'MONTH');
      await this.getList(this.prevYearGridField, this.getSearchFilterPrevYear(), false);
    },
    async getList(gridField, searchFilter, editDiv = false) {
      gridField.dataSource = [];
      gridField.gridColumns = [];
      gridField.fieldName = { team: [], per: [], sales: [], };

      const bsnPlanStdList = await SalesPlan.getStandard(searchFilter);

      if(bsnPlanStdList.length > 0) {
        gridField.gridColumns = this.createGridColumns(bsnPlanStdList, gridField, editDiv);
        await SalesPlan.getPlanYearList(searchFilter)
            .then((response) => {
              // 총 목표액 계산
              response.forEach(data => {
                data.total = gridField.fieldName.sales.map(filedName => data[filedName] || 0).reduce((acc, cur) => acc + cur, 0);
              });
              gridField.dataSource = response;
            })
            .catch((error) => {
              console.log("getPlanYearList.===>", error);
            });
      } else {
        this.errorToast(`${searchFilter.planYear}년의 영업계획기준이 생성되지 않았습니다.`);
      }
    },
    //endregion
    //region ******************************* 버튼 이벤트 *******************************
    //조회
    async onClickSearch() {
      await this.findList();
    },
    //마감년 변경시
    onYearChange() {
      this.onClickSearch();
    },
    //저장
    async onClickSaveSalesPlan() {
      const gridDataSource = this.gridField.dataSource;
      const changesData = this.$refs.grid.getBatchChanges();
      const params = {...changesData , planYear: this.searchFilter.planYear};
      const changeDataFlag = changesData.changedRecords.some((changeData) => {
        delete changeData._rid;
        const originalData = gridDataSource.find((f)=> f.planYm === changeData.planYm);
        return JSON.stringify(originalData) !== JSON.stringify(changeData);
      });
      if(!changeDataFlag || !changesData.changedRecords.length) {
        return this.errorToast('변경된 데이터가 없습니다');
      }
      try {
        await SalesPlan.patchStandard(params);
        await this.onClickSearch();
      } catch (e) {
        console.error(e);
      }
    },
    //영업계획 생성
    async onClickAddSalesPlan() {
      if (!(await this.confirm("영업계획을 생성하시겠습니까?"))) {
        return;
      }
      await SalesPlan.addPlanYmList(this.searchFilter);
      await this.getList(this.gridField, this.searchFilter);
    },
    //영업계획 삭제
    async onClickDeleteSalesPlan() {
      if (!(await this.confirm("이전 입력된 데이타가 모두 삭제되어 다시 입력하여야합니다. 삭제하시겠습니까?"))) {
        return;
      }
      await SalesPlan.deletePlanYmList(this.searchFilter);
      await this.getList(this.gridField, this.searchFilter);
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    //endregion
    //region ******************************* 팝업 이벤트 *******************************
    onClickCreateByDatePopupShow(bsnPlanYm) {
      this.isShowSalesPlanByDateSettingPopup = true;
      this.$nextTick(() => {
        this.$refs.createByDatePopup.initShow(bsnPlanYm);
      });
    },
    async onSalesPlanByDateSettingPopupConfirm(args) {
      this.isShowSalesPlanByDateSettingPopup = false;

      if(args.changedRecords.length > 0) {
        await SalesPlan.fetchPlanYmList(args);
        this.findList();
      }
    },
    onSalesPlanByDateSettingPopupClose() {
      this.isShowSalesPlanByDateSettingPopup = false;
    },
    //endregion
    //region ******************************* 공통 이벤트 *******************************
    getSearchFilterPrevYear() {
      return {
        planYear: moment(this.searchFilter.planYear).subtract(1, 'year').format(DATE_FORMAT_YYYY)
      };
    },
    createGridColumns(bsnPlanStdList, gridField, editDiv) {
      let teamCol = {}, perCol = {}, teamCols = [], perCols = [], salesCols = [];
      // 일간 계획 경우 주중 주말이 인원수를 앞 컬럼에서 계산 할 수 있으므로 1개의 컬럼만 가지면 됩니다.
      let isMultiPerColumns = (bsnPlanStdList.filter(data => data.planStdType.toString().substring(0,3)==='PER')).length > 1;
      let isMultiTeamColumns = (bsnPlanStdList.filter(data => data.planStdType === 'TEAM').length > 1);

      // 인원수, 팀수, 매출액
      for(const bsnPlanStd of bsnPlanStdList) {
        let filedName = bsnPlanStd.planStdType.toLowerCase()+'_'+bsnPlanStd.planStdId;
        gridField.fieldName[bsnPlanStd.planStdType.toLowerCase()]?.push(filedName);
        switch(bsnPlanStd.planStdType) {
          case "TEAM":
            teamCol = { field: filedName, headerText: bsnPlanStd.planStdNm, textAlign: 'right', allowEditing: editDiv, width: 60, visible: true, type: "number", isNumericType: true, };
            if(isMultiTeamColumns) {
              teamCols.push(teamCol);
            }
            break;
          case 'PERWEEKDAY':
          case 'PERWEEKEND':
            perCol = { field: filedName, headerText: bsnPlanStd.planStdNm, textAlign: 'right', allowEditing: editDiv, width: 60, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 2 }, };
            if(isMultiPerColumns) {
              perCols.push(perCol);
            }
            break;
          case "SALES":
            salesCols.push({ field: filedName, headerText: bsnPlanStd.planStdNm, textAlign: 'right', allowEditing: editDiv, width: 100, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 3 }, });
            break;
        }
      }
      console.log("perCols",perCols);
      return [
        { field: "planYm", headerText: "계획년월", textAlign: 'center', allowEditing: editDiv, width: 80, visible: true, type: "string", isDateType: true, isPrimaryKey: true,
          valueAccessor: (field, data) => { return moment(data[field]).format(DATE_FORMAT_YYYY_MM); },},
        { field: 'bsnDayCnt', headerText: '영업일수', columns: [
            { field: "weekday", headerText: '주중', textAlign: 'center', allowEditing: editDiv, width: 50, visible: true, type: "number", isNumericType: true, valueAccessor: (field, data) => { return data[field] ? data[field] : '-'; },},
            { field: "weekend", headerText: '주말', textAlign: 'center', allowEditing: editDiv, width: 50, visible: true, type: "number", isNumericType: true, valueAccessor: (field, data) => { return data[field] ? data[field] : '-'; }, },
          ],},
        ( isMultiTeamColumns ? { field: 'TEAM', headerText: '팀수', columns: teamCols, } : teamCol ),
        ( isMultiPerColumns ? { field: 'PER', headerText: '인원수', columns: perCols, } : perCol ),
        { field: "total", headerText: "총 목표액", textAlign: 'right', allowEditing: false, width: 150, visible: true, type: "number", isNumericType: true, inputNumberProperty: {allowDot: true ,maxUnderDotLength: 3 }, },
        { field: 'salesAmt', headerText: '매출액', columns: salesCols, }
      ];
    },
    floor(data, maxUnderDotLength){
      if(maxUnderDotLength < 1) {
        return data;
      }
      let pow = 10 ** maxUnderDotLength;
      return Math.floor(data * pow) / pow;
    },
    // 합계 Update
    recalculationTotal(rowData) {
      rowData.total = this.floor(this.gridField.fieldName.sales.map(fieldName => rowData[fieldName] || 0).reduce((acc, cur) => acc + cur, 0), 3);
    },

    //endregion
    //region ******************************* 그리드 이벤트 *******************************
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
      this.prevYearGridField.count = this.$refs.prevYearGrid.getGridBatchCount();
    },
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      //필수 입력
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (field === 'planYm' && this.salesPlanDiv === 'DAY') {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (field === 'planYm' && this.salesPlanDiv === 'DAY') {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    //그리드 셀 클릭
    onGridClick(args){
      const { column:{ field }, rowData: { planYm } } = args;
      if(field === "planYm" && this.salesPlanDiv === 'DAY') {
        this.onClickCreateByDatePopupShow(planYm);
      }
    },
    //그리드 저장 후
    async gridCellSaved(args){
      // 객단가에 맞춰 각 매출액 수정
      const { column:{ field }, rowData } = args;
      rowData[field] = args.value;
      this.recalculationTotal(rowData);
      this.$refs.grid.updateRow(this.$refs.grid.getRowIndexByPrimaryKey(args.rowData.calYm), rowData);
    }
    //endregion
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .body-article .section-header.prev-year-list {
  margin-top: 0;
}
</style>
